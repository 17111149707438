<template>
  <div class="medium">
    <div class="medium-banner">
      <div class="banner-head">
        <h2>河小象</h2>
        <p>少 儿 在 线 素 质 教 育</p>
      </div>
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/new-banner.png" />
    </div>
    <div class="medium-list">
      <div
        class="medium-box"
        v-for="(item,index) in list"
        :key="index"
        @click="goNews(item.newUrl)"
      >
        <img :src="item.newImg" class="newImg" />
        <div class="medium-msg">
          <h3>{{item.newTitle}}</h3>
          <p>{{item.newInfo}}</p>
          <div class="medium-info">
            <button>
              查看详情
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/new-btn.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    goNews (url) {
      window.open(url, '_blank')
    },
    getData () {
        //https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/news.json
       this.axios.get('https://sc2.hexiaoxiang.com/web_static/new_hx_web/news.json').then(res=>{
         this.list=res.data.list
       })
    }
  }
}
</script>
<style lang="scss">
.medium {
  .medium-banner {
    width: 100%;
    height: 620px;
    background: url('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/new-bg.png');
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 580px;
    }
    .banner-head {
      color: #00234d;
      text-align: left;
      margin-right: 38px;
      h2 {
        font-size: 70px;
        position: relative;
        &::after {
          content: '';
          width: 118px;
          height: 6px;
          background: #1295ff;
          position: absolute;
          bottom: -18px;
          left: 0;
        }
      }
      p {
        margin-top: 30px;
        font-size: 35px;
      }
    }
  }
  .medium-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1120px;
    margin: 0 auto 100px;
    .medium-box {
      padding: 35px 23px;
      box-shadow: 0px 14px 30px 0px rgba(236, 235, 235, 1);
      margin-top: 32px;
      cursor: pointer;
      display: flex;
      .newImg {
        width: 334px;
        height: 334px;
        margin-right: 36px;
        object-fit: contain;
      }
      .medium-msg {
        font-family: MicrosoftYaHeiUI;
        color: #444444;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 23px;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        p {
          font-size: 18px;
          line-height: 30px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          overflow: hidden;
          flex: 1;
          text-align: left;
        }
        .medium-info {
          display: flex;
          justify-content: end;
          color: #787878;
          font-size: 16px;
          margin-top: 19px;
          align-items: center;
          button {
            color: #ff7041;
            font-size: 16px;
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>